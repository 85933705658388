import { useEffect, useState } from 'react';
import { SiPayoneer } from 'react-icons/si';
import { IoMdArrowDropdown } from 'react-icons/io';
import { MdModeEditOutline } from 'react-icons/md';
import SalesDropdown from './SalesDropdown';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  fetchMyWalletAction,
  updateWalletBankDetailsAction,
} from '../../api/auth';
import LoootyLoader from '../loader/loootyLoader';
import LoadingState from '../loader/LoadingState';
import { useMediaQuery } from 'react-responsive';
import NavBarDropdownItem from '../navbar/NavBarDropdownItem ';
import { NavBarDropDown } from '../navbar/NavBarDropDown';

const Balance = () => {
  const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 768 });
  const [toggleFiatCrypto, setToggleFiatCrypto] = useState('fiat');
  const [priceSelect, setPriceSelect] = useState(50);
  const [selectedPaymentGateway, setSelectedPaymentGateway] =
    useState('payoneer');
  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [payoneerMail, setPayoneerMail] = useState('');

  const [paymentMerchant, setPaymentMerchant] = useState(null);
  const [balanceSettings, setBalanceSettings] = useState(null);
  const [activeFilter, setActiveFilter] = useState('');
  const [showSalesFilterContainer, setShowSalesFilterContainer] =
    useState(false);

  const clickMobileActiveFilter = (filterTitle) => {
    if (activeFilter.toLowerCase() === filterTitle.toLowerCase()) {
      setActiveFilter('');
      setShowSalesFilterContainer(false);
    } else {
      setActiveFilter(filterTitle);
      setShowSalesFilterContainer(true);
    }
  };

  const findBankDetailsByName = (bankDetails, name) => {
    let merchant = bankDetails.find(
      (merchant) => merchant.merchant_name === name
    );
    setPaymentMerchant(merchant);
  };
  const handleBankDetailsChange = (merchantName, newAccountId) => {
    const accountIndex = balanceSettings?.bank_details.findIndex(
      (account) => account.merchant_name === merchantName
    );

    if (accountIndex !== -1) {
      const updatedAccounts = { ...balanceSettings };

      updatedAccounts.bank_details[accountIndex] = {
        merchant_name: merchantName,
        account_type: toggleFiatCrypto,
        account_id: newAccountId,
      };
      setBalanceSettings(updatedAccounts);
      setPaymentMerchant(updatedAccounts.bank_details[accountIndex]);
    } else {
      let updatedSettings = balanceSettings;
      let obj = {
        merchant_name: merchantName,
        account_type: toggleFiatCrypto,
        account_id: newAccountId,
      };
      updatedSettings.bank_details.push(obj);
      setBalanceSettings(updatedSettings);
      setPaymentMerchant(obj);
    }
  };

  const updateBankDetails = async () => {
    setIsLoading(true);

    await dispatch(
      updateWalletBankDetailsAction({
        updateData: {
          email: payoneerMail,
        },
      })
    )
      .unwrap()
      .then(() => {
        toast.success('Payment details updated successfully!', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err.payload?.response?.message || 'Error try again later', {
          autoClose: 2000,
          className: 'toast__message',
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(fetchMyWalletAction())
      .then((res) => {
        if (isMounted) {
          setBalanceSettings(res?.payload);
          // setPriceSelect(res?.payload?.minimum_amount);
          findBankDetailsByName(res?.payload?.bank_details, 'paypal');
          setIsLoading(false);
        }
      })
      .catch((err) => {
        isMounted && setIsLoading(false);
        toast.error(
          err.payload?.response?.data?.message || 'Error try again later',
          {
            autoClose: 2000,
            className: 'toast__message',
          }
        );
      });

    return () => (isMounted = false);
  }, [dispatch]);
  return (
    <section className='user__sales'>
      <div className='user__sales-sidebar'>
        <section className='user__sales-sidebar-header'>
          <div
            onClick={(e) => setToggleFiatCrypto('fiat')}
            className={`user__sales-sidebar-header-item ${
              toggleFiatCrypto === 'fiat' && 'user__sales-shi-selected'
            }`}
          >
            FIAT
          </div>

          <div
            onClick={(e) => setToggleFiatCrypto('crypto')}
            className={`user__sales-sidebar-header-item ${
              toggleFiatCrypto === 'crypto' && 'user__sales-shi-selected'
            }`}
          >
            CRYPTO
          </div>
        </section>

        {toggleFiatCrypto === 'fiat' ? (
          <section className='user__sales-sidebar-body'>
            <div
              onClick={(e) => {
                setSelectedPaymentGateway('payoneer');
                findBankDetailsByName(balanceSettings?.bank_details, 'payoneer');
              }}
              className={`user__sales-sidebar-body-item ${
                selectedPaymentGateway === 'payoneer' &&
                'user__sales-sidebar-bi-selected'
              }`}
            >
              <span>
                <SiPayoneer /> Payoneer
              </span>
            </div>

            {/* <div
              onClick={(e) => {
                setSelectedPaymentGateway('stripe');
                findBankDetailsByName(balanceSettings?.bank_details, 'stripe');
              }}
              className={`user__sales-sidebar-body-item ${
                selectedPaymentGateway === 'stripe' &&
                'user__sales-sidebar-bi-selected'
              }`}
            >
              <span>
                <GrStripe /> Stripe
              </span>
            </div> */}
          </section>
        ) : toggleFiatCrypto === 'crypto' ? (
          <section className='user__sales-sidebar-body'></section>
        ) : null}
      </div>

      <div className='user__sales-main-content'>
        {isMobile && (
          <div
            className='landing__navbar-mobile-filter-box'
            style={{ display: 'flex' }}
          >
            <NavBarDropDown
              title={'Fiate'}
              isActive={activeFilter === 'fiate'}
              onClick={() => clickMobileActiveFilter('fiate')}
            />
            <NavBarDropDown
              title={'Crypto'}
              isActive={activeFilter === 'crypto'}
              onClick={() => clickMobileActiveFilter('crypto')}
            />
          </div>
        )}

        {showSalesFilterContainer && (
          <div className='nav_mobile_filter-options-container'>
            {activeFilter.toLowerCase() === 'fiate' ? (
              <>
                <NavBarDropdownItem option={'Paypal'} onClick={() => {}} />
                <NavBarDropdownItem option={'Stripe'} onClick={() => {}} />
              </>
            ) : (
              <NavBarDropdownItem option={'Coming Soon'} />
            )}
          </div>
        )}

        <section className='user__sales-mc-header'>
          <div className='user__sales-mc-left'>Notice:</div>
          <div className='user__sales-mc-right'>
            We send out payment every 1<sup>st</sup> and 15<sup>th</sup> of
            every month You must have a balance of $20 or more at the time to be
            eligible.
            <p>
              if you do not meet the minimum balance, no worries, we will try
              again next time.
            </p>
          </div>
        </section>

        {isLoading ? (
          <LoadingState />
        ) : (
          <section className='user__sales-mc-body'>
            <div className='user__sales-mc-item-container'>
              <div className='user__sales-mc-item-header'>PAYOUT BALANCE</div>

              <div className='user__sales-mc-item-body'>
                <div className='user__sales-form-group'>
                  <div
                    onMouseEnter={() => setShowDropdown(true)}
                    onMouseLeave={() => setShowDropdown(false)}
                    className='user__sales-mc-dropdown'
                  >
                    <span>${priceSelect}</span>
                  </div>

                  <SalesDropdown
                    setSelected={setPriceSelect}
                    onMouseEnter={() => setShowDropdown(true)}
                    onMouseLeave={() => setShowDropdown(false)}
                    showDropdown={showDropdown}
                    setShowDropdown={setShowDropdown}
                    initialMinimumBalance={priceSelect}
                  />
                  <IoMdArrowDropdown className='user__sales-mc-dropdown-icon' />
                </div>
                <p>
                  Payouts will only be sent when your balance reaches $
                  {priceSelect}
                </p>
              </div>
            </div>

            {selectedPaymentGateway === 'payoneer' && (
              <div className='user__sales-mc-item-container'>
                <div className='user__sales-mc-item-header'>PAYONEER EMAIL</div>

                <div className='user__sales-mc-item-body'>
                  <div className='user__sales-form-group'>
                    <div className='user__sales-skew-container'>
                      <input
                        placeholder='Fill in your Email here'
                        className='user__sales-mc-input'
                        value={payoneerMail}
                        onChange={(e) => setPayoneerMail(e.target.value)}
                      />
                    </div>
                    <div className='user__sales-mc-input-edit-skew-container'>
                      <MdModeEditOutline className='user__sales-mc-input-edit' />
                    </div>
                  </div>
                  <p>This is the Email we use for payouts</p>
                </div>
              </div>
            )}

            {selectedPaymentGateway === 'stripe' && (
              <div className='user__sales-mc-item-container'>
                <div className='user__sales-mc-item-header'>STRIPE EMAIL</div>

                <div className='user__sales-mc-item-body'>
                  <div className='user__sales-form-group'>
                    <div className='user__sales-skew-container'>
                      <input
                        placeholder='Fill in your Email here'
                        className='user__sales-mc-input'
                        value={paymentMerchant?.account_id || ''}
                        onChange={(e) =>
                          handleBankDetailsChange('paypal', e.target.value)
                        }
                      />
                    </div>
                    <div className='user__sales-mc-input-edit-skew-container'>
                      <MdModeEditOutline className='user__sales-mc-input-edit' />
                    </div>
                  </div>
                  <p>This is the Email we use for payouts</p>
                </div>
              </div>
            )}

            <div className='user__sales-mc-item-container button'>
              <button
                className='user__sales-mc-item-btn'
                type='button'
                onClick={updateBankDetails}
              >
                <span>{isLoading ? <LoootyLoader /> : 'SAVE'}</span>
              </button>
            </div>
          </section>
        )}
      </div>
    </section>
  );
};

export default Balance;
