import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
//import { useNavigate } from 'react-router-dom';

const DayImg = '/assets/image/day.png';
const NightImg = '/assets/image/night.png';

const ListExplainRight = ({ style }) => {
  return (
    <section style={style} className='landing__partner-store-discuss'>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <div className='landing__first-col-container'>
          <div className='landing__first-col-box'></div>

          <p className='landing__first-col-text'>
            Earn money everytime someone you referred makes a purchase
          </p>
        </div>

        <div className='landing__first-col-container'>
          <div className='landing__first-col-box'></div>

          <p className='landing__first-col-text'>
            Passive income just from spreading the word about loooty
          </p>
        </div>

        <div className='landing__first-col-container'>
          <div className='landing__first-col-box'></div>

          <p className='landing__first-col-text'>
            Epic Library of curated content to choose from
          </p>
        </div>
      </div>
    </section>
  );
};

const ListExplainLeft = ({ style }) => {
  return (
    <section
      style={style}
      className='landing__partner-store-discuss-left'
    >
      <div className='landing__first-col-container'>
        <p className='landing__first-col-text'>
          Join the African Gaming and Animation Industry
        </p>

        <div className='landing__first-col-box'></div>
      </div>

      <div className='landing__first-col-container'>
        <p className='landing__first-col-text'>
          Earn money by selling your Art 
        </p>

        <div className='landing__first-col-box'></div>
      </div>
        
      <div className='landing__first-col-container'>
        <p className='landing__first-col-text'>
          Add to the wealth of knowledge on the African lore
        </p>

        <div className='landing__first-col-box'></div>
      </div>
    </section>
  );
};

const PartnerStore = () => {
  const [partnerOpen, setPartnerOpen] = useState(false);
  //const navigate = useNavigate();
  const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 768 });
  const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 768 });

  return (
    <section className='landing__open-partner-container'>
      <div className='landing__open-partner-inner-container'>
        {partnerOpen && (
          <ListExplainRight
            style={{
              //left: '6.8%',
              //alignItems: 'flex-end',
              //textAlign: 'right'
            }}
          />
        )}
        {!partnerOpen && (
          <ListExplainLeft
            style={{
              left: '6.8%',
              alignItems: 'flex-end' 
            }}
          />
        )}

        <div
          onMouseEnter={(e) => setPartnerOpen(false)}
          className='landing__open-img-container'
        >
          <img src={NightImg} alt='' className='landing__open-img' />
          {partnerOpen && <div className='landing__img-overlay-open'></div>}
        </div>

        <div
          onMouseEnter={(e) => setPartnerOpen(true)}
          className='landing__partner-img-container'
        >
          <img src={DayImg} alt='' className='landing__partner-img' />
          {!partnerOpen && <div className='landing__img-overlay-partner'></div>}
        </div>

        <div
          style={{
            left:
              isTablet && partnerOpen
                ? '10%'
                : isTablet && !partnerOpen
                ? '14%'
                : isMobile && partnerOpen
                ? '22%'
                : '22%',
          }}
          className='landing__open-partner-label-container'
        >
          <div
            onMouseEnter={(e) => setPartnerOpen(false)}
            //onClick={() => navigate('/open/store')}
            style={{
              backgroundColor: !partnerOpen ? '#fff' : '#16151C',
              color: partnerOpen ? '#fff' : '#16151C',
              opacity: !partnerOpen ? '1' : '1',
            }}
            className='landing__bg-label-partner'
          >
            <h2 className='landing__open-label-header'>Early Access Creator</h2>
            <p className='landing__open-label-desc'>
              Become an {/*<a href='/open/store' style={{color: '#ffdd17'}}> free store </a>*/} Early Access Creator and easily start
              selling your digital assets on Loooty.
            </p>
          </div>

          <div
            onMouseEnter={(e) => setPartnerOpen(true)}
            style={{
              backgroundColor: partnerOpen ? '#fff' : '#16151C',
              color: !partnerOpen ? '#fff' : '#16151C',
              opacity: partnerOpen ? '1' : '1',
            }}
            className='landing__bg-label-open'
          >
            <h2 className='landing__partner-label-header'>Become a partner</h2>
            <p className='landing__partner-label-desc'>
              Become an affiliate partner and earn on sales you
              help refer. Coming soon...
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PartnerStore;
