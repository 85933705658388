import { FaDiscord, FaTelegram } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from 'react-icons/fa';
//import { RiInstagramFill } from 'react-icons/ri';
import { HiOutlineSearch } from 'react-icons/hi';
//import { useNavigate } from 'react-router-dom';
import { subscribeWebhook } from '../../api/coming';
import { useState } from 'react';
import { toast } from 'react-toastify';
// import { LoootyLogo } from "../loootyLogo"

export const SubscribeInput = ({ style, btnStyle, controlStyle }) => {
  const [email, setEmail] = useState('');

  const handleSubscribe = async (e) => {
    e.preventDefault();
    try {
      await subscribeWebhook({ email });
      toast.success('Subscription successful, Thank you!', {
        autoClose: 2000,
        className: 'toast__message',
      });
    } catch (error) {
      console.error('Error subscribing:', error.message);
      toast.error('Subscription failed. Please try again.', {
        autoClose: 2000,
        className: 'toast__message',
      });
    } finally {
      setEmail('');
    }
  };
  return (
    <form
      style={style}
      className='landing__news-letter-form'
      onSubmit={(e) => handleSubscribe(e)}
    >
      <div className='landing__news-letter-form-group'>
        <input
          style={controlStyle}
          placeholder='Email Address'
          className='landing__news-letter-form-control'
          type='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button
          style={btnStyle}
          className='landing__news-letter-btn-2'
          type='submit'
        >
          <span className='landing__news-letter-btn-text'>SUBSCRIBE</span>
        </button>
      </div>
    </form>
  );
};

export const SearchInput = ({
  style,
  btnStyle,
  disabled,
  controlStyle,
  searchString,
  setSearchString,
  onSearch,
}) => {
  return (
    <div style={style} className='landing__news-letter-form'>
      <form
        className='landing__news-letter-form-group'
        onSubmit={(e) => {
          e.preventDefault();
          onSearch();
        }}
      >
        <input
          style={controlStyle}
          placeholder='Search products'
          className='landing__news-letter-form-control'
          value={searchString || ''}
          onChange={(e) => setSearchString(e.target.value)}
          disabled={disabled}
        />
        <button
          style={btnStyle}
          className='landing__news-letter-btn-2'
          type='button'
        >
          <span className='landing__news-letter-btn-text'>
            <HiOutlineSearch />
          </span>
        </button>
      </form>
    </div>
  );
};

const Footer = ({ categories }) => {
  //const navigate = useNavigate();
  return (
    <div className='landing__footer-inner'>
      <div className='landing__footer-socials'>
        {/* <LoootyLogo style={{height: "60px", zIndex: "0"}}/> */}
        <div className='landing__socials-container'>
          <a
            href='https://instagram.com/loootystore?igshid=NTc4MTIwNjQ2YQ=='
            rel='noopener noreferrer'
            target='_blank'
          >
            <FaInstagram
              style={{
                fontSize: '1.5rem',
                padding: '.2rem',
                //borderRadius: '.4rem',
                color: 'white',
                //backgroundColor: '#D22D89',
              }}
            />
          </a>
          <a
            href='https://twitter.com/loootystore?t=nfX-NSZrbTszIxoKLx-7kA&s=09'
            rel='noopener noreferrer'
            target='_blank'
          >
            <FaXTwitter
              style={{
                fontSize: '1.5rem',
                padding: '.2rem',
                //borderRadius: '.4rem',
                color: 'white',
                //color: '#54ABEE',
                //backgroundColor: 'transparent',
              }}
            />
          </a>
          <a
            href='https://t.me/loootystore'
            rel='noopener noreferrer'
            target='_blank'
          >
            <FaTelegram
              style={{
                fontSize: '1.5rem',
                padding: '.2rem',
                //borderRadius: '.4rem',
                color: 'white',
                //backgroundColor: '#3B5997',
              }}
            />
          </a>
          <a
            href='https://discord.gg/ubJmSxEQhf'
            rel='noopener noreferrer'
            target='_blank'
          >
            <FaDiscord
              style={{
                fontSize: '1.5rem',
                padding: '.2rem',
                //borderRadius: '.4rem',
                color: 'white',
                //backgroundColor: '#3B5997',
              }}
            />
          </a>
        </div>

        <div className='landing__copyright-container'>
          2025 {'(c)'} Loooty.com. All rights reserved.
        </div>
      </div>

      {/* <div className='landing__footer-resources'>
        <h2 className='landing__footer-resources-header'>RESOURCES</h2>

        <div className='landing__footer-resources-list-cont'>
          {categories?.slice(0, 9).map((category) => (
            <span
              className='landing__footer-resource-list'
              key={category.id}
              onClick={() =>
                navigate(`/search/result/${category.catalogue_title}`)
              }
            >
              {category.catalogue_title}
            </span>
          ))}
        </div>
      </div> */}

      <div className='landing__footer-resources'>
        <h2 className='landing__footer-resources-header'>COMMUNITY</h2>

        <div className='landing__footer-resources-list-cont'>
          <span
            className='landing__footer-resource-list'
            //onClick={() => navigate('/open/store')}
          >
            <a href='https://discord.gg/ubJmSxEQhf'>Become a creator</a>
          </span>

          {/* <span className="landing__footer-resource-list">
                            Become an Affiliate
                        </span>

                        <span className="landing__footer-resource-list">
                            Community
                        </span>

                        <span className="landing__footer-resource-list">
                            Blog
                        </span> */}
        </div>
      </div>

      <div className='landing__footer-resources'>
        <h2 className='landing__footer-resources-header'>COMPANY</h2>

        <div className='landing__footer-resources-list-cont'>
          <a href='/privacy/policy'>
            <span className='landing__footer-resource-list'>
              Privacy Policy
            </span>
          </a>
          <a href='/terms/conditions'>
            <span className='landing__footer-resource-list'>
              Terms and conditions
            </span>
          </a>
          <span className='landing__footer-resource-list'>
            Contact us{' '}
            <a href='mailto:support@loooty.com'>support@loooty.com</a>
          </span>
        </div>
      </div>

      <div className='landing__footer-news-letter'>
        <h2 className='landing__footer-resources-header'>NEWSLETTER</h2>

        <div className='landing__footer-news-letter-info'>
          <p>
            Subscribe to the loooty news letter to get your weekly dose of news,
          </p>
          <p> updates, tips and special offers.</p>
        </div>

        <SubscribeInput />
      </div>
    </div>
  );
};

export default Footer;