import { useEffect, useState } from 'react';
//import { FaPaypal } from 'react-icons/fa';
import { FcSettings } from 'react-icons/fc';
//import { GrStripe } from 'react-icons/gr';
import { ContentRowSales } from '../admin/home/ContentRow';
import { OrangeBtn } from '../landing/slogan';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStoreStatsAction } from '../../api/auth';
import { toast } from 'react-toastify';
import LoadingState from '../loader/LoadingState';
import WithdrawalModal from '../modals/sales/WithdrawalModal';
import { useNavigate } from 'react-router';
import { formatCurrency } from '../admin/inpage/HomeAdmin';

export const getDateRange = (duration) => {
  let start_date;
  const end_date = new Date().toISOString().split('T')[0];

  switch (duration) {
    case '1 week':
      start_date = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
        .toISOString()
        .split('T')[0];
      break;
    case '1 month':
      start_date = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
        .toISOString()
        .split('T')[0];
      break;
    case '2 months':
      start_date = new Date(Date.now() - 60 * 24 * 60 * 60 * 1000)
        .toISOString()
        .split('T')[0];
      break;
    case '3 months':
      start_date = new Date(Date.now() - 90 * 24 * 60 * 60 * 1000)
        .toISOString()
        .split('T')[0];
      break;
    case '4 months':
      start_date = new Date(Date.now() - 120 * 24 * 60 * 60 * 1000)
        .toISOString()
        .split('T')[0];
      break;
    case '6 months':
      start_date = new Date(Date.now() - 180 * 24 * 60 * 60 * 1000)
        .toISOString()
        .split('T')[0];
      break;
    case '12 months':
      start_date = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000)
        .toISOString()
        .split('T')[0];
      break;
    default:
      start_date = null;
  }

  return { start_date, end_date };
};

export const IrregularBtn = ({ onClick }) => (
  <div className='user__sales-irregular-btn' onClick={onClick}>
    <div className='user__sales-irregular-spacer'></div>
    <FcSettings className='user__sales-irregular-btn-icon' />
  </div>
);

export const BalanceAnnounceCard = ({ title, value, purple, yellow }) => {
  return (
    <div
      className={`user__sales-balance-ann-card ${purple && 'selected-purple'} ${
        yellow && 'selected-yellow'
      }`}
    >
      <span className='user__sales-balance-ann-card-title'>{title}</span>
      <span className='user__sales-balance-ann-card-value'>{value}</span>
    </div>
  );
};

const dropdownItems = [
  '1 week',
  '1 month',
  '2 months',
  '3 months',
  '4 months',
  '6 months',
  '12 months',
];

export const DropDownBox = ({ selected, setSelected }) => {
  return (
    <select
      className='user__sales-statistics-time-dropdown'
      value={selected || ''}
      onChange={(e) => setSelected(e.target.value)}
    >
      {dropdownItems?.map((item) => (
        <option
          key={item}
          value={item}
          className='user__sales-statistics-drop-item'
        >
          {item}
        </option>
      ))}
    </select>
  );
};

export const StatisticsBar = ({ percentage, date }) => {
  const inputDate = new Date();
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const month = monthNames[inputDate.getMonth()];
  const day = inputDate.getDate();
  const year = inputDate.getFullYear();

  const formattedDate = `${month} ${day}, ${year}`;
  return (
    <section className='user__sales-statistics-bar'>
      <div
        style={{ height: `${percentage + 20}%` }}
        className='user__sales-statistics-bar-percentage'
      >
        <span>{percentage}</span>
      </div>
      <span className='user__sales-statistics-bar-dater'>{formattedDate}</span>
    </section>
  );
};

const StatisticsBox = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const store = useSelector((state) => state.user.merchandiseStore);
  const [selectedDateRange, setSelectedDateRange] = useState('1 month');
  const [salesStats, setSalesStats] = useState(null);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    let dateRange = getDateRange(selectedDateRange);

    dispatch(
      fetchStoreStatsAction({
        storeData: {
          store_id: store?.id,
          ...dateRange,
        },
      })
    )
      .then((res) => {
        if (isMounted) {
          setSalesStats(res?.payload);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        isMounted && setIsLoading(false);
        toast.error(
          err.payload?.response?.data?.message || 'Error try again later'
        );
      });

    return () => (isMounted = false);
  }, [dispatch, store?.id, selectedDateRange]);
  return (
    <div className='user__sales-statistics-box'>
      {isLoading ? (
        <LoadingState />
      ) : (
        <div>
          <div className='user__sales-statistics-box-header'>
            <h1 className='user__sales-header-text'>Sales of your product</h1>
            <DropDownBox
              selected={selectedDateRange}
              setSelected={setSelectedDateRange}
            />
          </div>
          <div className='user__sales-statistics-box-body'>
            <div className='user__sales-stats-side'>
              <span className='user__sales-stats-side-count'> 1</span>
              <span className='user__sales-stats-side-count'> 2</span>
            </div>
            <div className='user__sales-statistics-box-body-inner'>
              {salesStats && Array.isArray(salesStats) ? (
                salesStats?.map((item) => {
                  return (
                    <StatisticsBar
                      key={item.date}
                      percentage={item.count}
                      date={item.date}
                    />
                  );
                })
              ) : salesStats !== null && typeof salesStats === 'object' ? (
                <StatisticsBar
                  key={salesStats.updated_at}
                  percentage={salesStats.stats.orders.distinct_days_orders}
                  date={salesStats.updated_at}
                />
              ) : (
                <div
                  className='user__no-product-div'
                  style={{ width: 'auto', margin: 'auto', height: 'auto' }}
                >
                  You have not made any sales yet!
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Sales = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggleFiatCrypto, setToggleFiatCrypto] = useState('fiat');
  const [selectedPaymentGateway, setSelectedPaymentGateway] =
    useState('paypal');
  const [isLoading, setIsLoading] = useState(false);
  const store = useSelector((state) => state.user.merchandiseStore);
  const [storePayoutStats, setStorePayoutStats] = useState(null);
  const [showWithdrawalModal, setShowWithdrawalModal] = useState(false);
  const currentDate = new Date();
  // Extract the day of the month from the current date
  const currentDayOfMonth = currentDate.getDate(); // eg 15

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    let dateRange = getDateRange('1 month');

    dispatch(
      fetchStoreStatsAction({
        storeData: {
          store_id: store?.id,
          ...dateRange,
        },
      })
    )
      .then((res) => {
        console.log({ res });
        if (isMounted) {
          setStorePayoutStats(res?.payload?.stats.orders);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        isMounted && setIsLoading(false);
        toast.error(
          err.payload?.response?.data?.message || 'Error try again later'
        );
      });

    return () => (isMounted = false);
  }, [dispatch, store?.id]);

  const [firstSalesActivitiesStats, setFirstSalesActivitiesStats] = useState(
    {}
  );
  const [secondSalesActivitiesStats, setSecondSalesActivitiesStats] = useState(
    {}
  );

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    dispatch(
      fetchStoreStatsAction({
        storeData: {
          store_id: store?.id,
          start_date: new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            1
          )
            .toISOString()
            .split('T')[0],
          end_date: new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            15
          )
            .toISOString()
            .split('T')[0],
        },
      })
    )
      .then((res) => {
        console.log({ res });
        if (isMounted) {
          setFirstSalesActivitiesStats(res?.payload?.stats.orders);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        isMounted && setIsLoading(false);
        toast.error(
          err.payload?.response?.data?.message || 'Error try again later'
        );
      });

    return () => (isMounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, store?.id]);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    let dateRange = getDateRange('1 month');

    if (currentDayOfMonth > 15) {
      dispatch(
        fetchStoreStatsAction({
          storeData: {
            store_id: store?.id,
            start_date: new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              1
            )
              .toISOString()
              .split('T')[0],
            end_date: dateRange.end_date,
          },
        })
      )
        .then((res) => {
          console.log({ res });
          if (isMounted) {
            setSecondSalesActivitiesStats(res?.payload?.stats.orders);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          isMounted && setIsLoading(false);
          toast.error(
            err.payload?.response?.data?.message || 'Error try again later'
          );
        });
    }

    return () => (isMounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, store?.id]);

  return (
    <section className='user__sales'>
      {showWithdrawalModal && (
        <WithdrawalModal
          toggleShowModal={() => setShowWithdrawalModal(false)}
        />
      )}

      <div className='user__sales-sidebar'>
        <section className='user__sales-sidebar-header'>
          <div
            onClick={(e) => setToggleFiatCrypto('fiat')}
            className={`user__sales-sidebar-header-item ${
              toggleFiatCrypto === 'fiat' && 'user__sales-shi-selected'
            }`}
          >
            FIAT
          </div>

          <div
            onClick={(e) => setToggleFiatCrypto('crypto')}
            className={`user__sales-sidebar-header-item ${
              toggleFiatCrypto === 'crypto' && 'user__sales-shi-selected'
            }`}
          >
            CRYPTO
          </div>
        </section>

        <section className='user__sales-sidebar-body'>
          <div
            onClick={(e) => setSelectedPaymentGateway('paypal')}
            className={`user__sales-sidebar-body-item ${
              selectedPaymentGateway === 'paypal' &&
              'user__sales-sidebar-bi-selected'
            }`}
          >
            <span>
              Sales&nbsp;Report
            </span>
          </div>

          {/*<div
            onClick={(e) => setSelectedPaymentGateway('stripe')}
            className={`user__sales-sidebar-body-item ${
              selectedPaymentGateway === 'stripe' &&
              'user__sales-sidebar-bi-selected'
            }`}
          >
            <span>
              Affiliate&nbsp;Contract
            </span>
          </div>*/}
        </section>
      </div>

      <div className='user__sales-main-content'>
        <section className='user__sales-mc-header' style={{ color: '#fafafa' }}>
          <div className='user__sales-mc-left'>Notice:</div>
          <div className='user__sales-mc-right'>
            We send out payment every 1<sup>st</sup> and 15<sup>th</sup> of
            every month You must have a balance of $50 or more at the time to be
            eligible.
            <p>
              if you do not meet the minimum balance, no worries, we will try
              again next time.
            </p>
          </div>
        </section>

        <section className='user__sales-balance-body'>
          <section className='user__sales-balance-first-row'>
            <div className='user__sales-balance-announcer-container'>
              {/*<BalanceAnnounceCard
                title='Daily Average Orders:'
                value={
                  isLoading
                    ? '...'
                    : parseFloat(
                        storePayoutStats?.daily_average_orders
                          .toFixed(2)
                          .toLocaleString()
                      )
                }
              />*/}
              <BalanceAnnounceCard
                title='Total Orders:'
                value={isLoading ? '...' : storePayoutStats?.total_orders}
                purple={true}
              />
              <BalanceAnnounceCard
                title='Total Sales:'
                value={
                  isLoading
                    ? '...'
                    : formatCurrency(storePayoutStats?.order_amount)
                }
              />
            </div>
            <StatisticsBox />
          </section>

          {isLoading ? (
            <LoadingState />
          ) : (
            <section className='user__sales-balance-second-row'>
              <div className='user__sales-next-payout'>
                {' '}
                <h3 className='user__sales-next-payout-text'>NEXT PAYOUT</h3>
              </div>

              <div className='admin__home-ra-inner'>
                <h3 className='admin__home-ra-header'>
                  Sales and activities from October 1st to October 15th
                </h3>
                {storePayoutStats ? (
                  <div className='admin__home-ra-content'>
                    <ContentRowSales
                      header='Sales'
                      date={`$ ${firstSalesActivitiesStats?.order_paid_sub_amount}`}
                      target={`$ ${
                        secondSalesActivitiesStats?.order_paid_sub_amount ?? 0
                      }`}
                    />
                    <ContentRowSales
                      header='Marketplace fees'
                      date={`$ ${firstSalesActivitiesStats?.order_paid_service_fee}`}
                      target={`$ ${
                        secondSalesActivitiesStats?.order_paid_service_fee ?? 0
                      }`}
                    />
                    <ContentRowSales
                      header='Affiliate fees'
                      date={`$ ${
                        firstSalesActivitiesStats?.order_paid_affiliate_fee || 0
                      }`}
                      target={`$ ${
                        secondSalesActivitiesStats?.order_paid_affiliate_fee ||
                        0
                      }`}
                    />
                  </div>
                ) : null}
              </div>
              <div className='user__sales-next-payouts'>
                <BalanceAnnounceCard
                  title='Ready for Payout'
                  value={storePayoutStats?.closed_orders}
                  yellow={true}
                />
                <div className='user__sales-payout-withdraw-btn'>
                  <OrangeBtn
                    text='WITHDRAW'
                    style={{ padding: '1rem 5.5rem' }}
                    onClick={() => setShowWithdrawalModal(true)}
                  />
                  <IrregularBtn
                    onClick={() => navigate(`/user/payout settings`)}
                  />
                </div>
              </div>
            </section>
          )}
        </section>
      </div>
    </section>
  );
};

export default Sales;
