import { useState } from "react";
import { FaSave } from "react-icons/fa";
import { MdEdit } from "react-icons/md";

const ProfileInput = ({
  labelName,
  onChange,
  value,
  type,
  onClick,
  description,
}) => {
  const [save, setIsSave] = useState(true);
  return (
    <div className="user__main-profile-form-group">
      <div className="user__label-container">
        <label className="user__main-profile-label">
          <span className={"user__main-profile-label-inner"}>{labelName}</span>
        </label>
      </div>

      <section className="user__main-profile-form-control-container">
        <div className="user__main-profile-skew-container">
          <input
            type={type}
            disabled={save}
            onChange={onChange}
            value={value || ""}
            className="user__main-profile-form-control"
          />
          <span className="user__main-profile-form-control-edit-btn">
            {save ? (
              <MdEdit
                onClick={() => setIsSave((old) => !old)}
                style={{ cursor: "pointer", transform: "skewX(20deg)" }}
              />
            ) : (
              <FaSave
                onClick={async () => {
                  await onClick();
                  setIsSave((old) => !old);
                }}
                style={{ cursor: "pointer", transform: "skewX(20deg)" }}
              />
            )}
          </span>
        </div>

        {labelName === "USERNAME" && (
          <span className="user__main-profile-info">
            This name will appear on your customers billing statement
          </span>
        )}
        {labelName === "EMAIL" && (
          <span className="user__main-profile-info">
            PLEASE CHECK YOUR INBOX FOR A VERIFICATION EMAIL, IF YOU HAVE ALREADY VERIFIED YOUR EMAIL (IGNORE THIS)
          </span>
        )}
      </section>
    </div>
  );
};

export default ProfileInput;
