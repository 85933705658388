import { LoootyLogoStatic } from "../loootyLogo";
import { FaEyeSlash } from "react-icons/fa";
import CheckButtons from "../buttons/checkButtons";
import { useContext, useState } from "react";
import { RegisterContext } from "./context/registerContext";
import { Link, useNavigate } from "react-router-dom";
import { signUpAction } from "../../api/auth";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { BsEyeFill } from "react-icons/bs";
import LoootyLoader from "../loader/loootyLoader";
//import { FcGoogle } from 'react-icons/fc';
import "./auth.css";

function validatePassword(password) {
  const lengthRegex = /^.{8,}$/; // Minimum 8 characters
  const capitalLetterRegex = /[A-Z]/; // At least one uppercase letter
  const symbolRegex = /[!@#$%^&*()_+{}:;<>,.?~\\-]/; // At least one symbol

  if (
    lengthRegex.test(password) &&
    capitalLetterRegex.test(password) &&
    symbolRegex.test(password)
  ) {
    return true;
  } else {
    return false;
  }
}

const SignUpModal = () => {
  const [active, setActive] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const dispatch = useDispatch();
  const [showRegModal, setShowRegModal] = useContext(RegisterContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [hidePassword, setHidePassword] = useState({
    pass: true,
    rePass: true,
  });
  const [clickProtect, setClickProtect] = useState(false);

   //const web_url = process.env.REACT_APP_WEB_URL || '';

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    password: false,
    password_confirmation: false,
  });

  const [regInfo, setRegInfo] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
  });

   /*const googleWebSignIn = () => {
     let data = {
       scope: 'openid+profile+email',
       include_granted_scopes: 'true',
       response_type: 'token',
       state: 'state_parameter_passthrough_value',
       redirect_uri: `${web_url}/api/auth/socialite/google/callback`,
       client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
     };

     return `https://accounts.google.com/o/oauth2/v2/auth?scope=${data.scope}&include_granted_scopes=${data.include_granted_scopes}&response_type=${data.response_type}&state=${data.state}&redirect_uri=${data.redirect_uri}&client_id=${data.client_id}`;
   };*/

  const handleSignUp = async (e) => {
    e.preventDefault();

    if (!validatePassword(regInfo.password)) {
      toast.error(
        "Password must be atleast 8 characters, including a capital letter and a symbol",
        {
          autoClose: 2000,
          className: "toast__message",
        }
      );
      return;
    }
    if (regInfo.password !== regInfo.password_confirmation) {
      toast.error("Passwords do not match", {
        autoClose: 2000,
        className: "toast__message",
      });
      return;
    }
    if (!active) {
      toast.error("Please accept the terms and conditions", {
        autoClose: 2000,
        className: "toast__message",
      });
      return;
    }
    setIsLoading(true);
    setClickProtect(true);

    await dispatch(signUpAction({ signUpData: regInfo }))
      .unwrap()
      .then(async (result) => {
        setIsLoading(false);
        toast.success("Sign up was successful", {
          autoClose: 2000,
          className: "toast__message",
        });
        navigate("/");

        setShowRegModal({ ...showRegModal, register: false, login: true });
      })
      .catch((err) => {
        setIsLoading(false);
        setClickProtect(false);

        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: "toast__message",
          });
          if (err.response.data.errors) {
            if (err.response.data.errors.name) {
              setErrors((old) => ({ ...old, name: true }));
              toast.error(err.response.data.errors.name[0], {
                autoClose: 2000,
                className: "toast__message",
              });
            }

            if (err.response.data.errors.email) {
              setErrors((old) => ({ ...old, email: true }));

              toast.error(err.response.data.errors.email[0], {
                autoClose: 2000,
                className: "toast__message",
              });
            }

            if (err.response.data.errors.password) {
              setErrors((old) => ({ ...old, password: true }));

              toast.error(err.response.data.errors.password[0], {
                autoClose: 2000,
                className: "toast__message",
              });
            }
            if (err.response.data.errors.password_confirmation) {
              setErrors((old) => ({ ...old, password_confirmation: true }));

              toast.error(err.response.data.errors.password_confirmation[0], {
                autoClose: 2000,
                className: "toast__message",
              });
            }
          }
        }
      });
  };

  return (
    <div
      className={`reg__sign-up-overlay ${fadeOut ? "lgn__fade-out-anim" : ""}`}
    >
      <section className="reg__sign-up-modal">
        <div className="reg__close-btn-container">
          <div
            onClick={() => {
              setFadeOut((prev) => !prev);
              setTimeout(() => {
                setShowRegModal({ ...showRegModal, register: false });
              }, 600);
            }}
            className="reg__close-btn"
          >
            <span className="reg__close-btn-text">X</span>
          </div>
        </div>
        <div className="reg__loooty-innitials-container">
          <div className="reg_logo-container">
            <LoootyLogoStatic style={{ width: "15rem" }} />
          </div>
          <div className="reg__loooty-about">
            <div>Welcome to our little vault.</div>
            <div style={{ width: "75%" }}>Grab your loot!</div>
          </div>

          <ul className="reg__loooty-features">
            <li className="reg__loooty-features-item">
              Advance search and filtering options
            </li>
            <li className="reg__loooty-features-item">
              Add art work to custom selection
            </li>
            <li className="reg__loooty-features-item">
              Understand artworks with tags
            </li>
            {/* <li className='reg__loooty-features-item'>
              Uploader and collection suscription
            </li> */}
          </ul>
        </div>

        <div className="reg__sign-up-forms-container">
          <form onSubmit={handleSignUp} className="reg__sign-up-form">
            <div className="reg__form-group-container">
              <div
                style={{
                  border: errors.name
                    ? ".2rem solid #df4759"
                    : ".2rem solid #2B2B38",
                }}
                className="reg__form-group"
              >
                <input
                  onChange={(e) =>
                    setRegInfo((old) => ({ ...old, name: e.target.value }))
                  }
                  onFocus={() => {
                    setErrors((old) => ({ ...old, name: false }));
                  }}
                  value={regInfo.name}
                  className="reg__form-control"
                  placeholder="Username"
                />
                <span
                  style={{ visibility: "hidden" }}
                  className="sign__hide-sensitive-info-button"
                >
                  <BsEyeFill className="lgn__hide-sensitive-ib-icon" />
                </span>
              </div>

              <div
                style={{
                  border: errors.email
                    ? ".2rem solid #df4759"
                    : ".2rem solid #2B2B38",
                }}
                className="reg__form-group"
              >
                <input
                  onChange={(e) =>
                    setRegInfo((old) => ({ ...old, email: e.target.value }))
                  }
                  onFocus={() => {
                    setErrors((old) => ({ ...old, email: false }));
                  }}
                  value={regInfo.email}
                  className="reg__form-control"
                  type={"email"}
                  placeholder=" Email"
                />
                <span
                  style={{ visibility: "hidden" }}
                  className="sign__hide-sensitive-info-button"
                >
                  <BsEyeFill className="lgn__hide-sensitive-ib-icon" />
                </span>
              </div>

              <div
                style={{
                  border: errors.password
                    ? ".2rem solid #df4759"
                    : ".2rem solid #2B2B38",
                }}
                className="reg__form-group"
              >
                <input
                  onChange={(e) =>
                    setRegInfo((old) => ({ ...old, password: e.target.value }))
                  }
                  onFocus={() => {
                    setErrors((old) => ({ ...old, password: false }));
                  }}
                  value={regInfo.password}
                  type={hidePassword.pass ? "password" : "text"}
                  className="reg__form-control"
                  placeholder="Password"
                />
                <span
                  onClick={() =>
                    setHidePassword((old) => ({
                      ...old,
                      pass: !hidePassword.pass,
                    }))
                  }
                  className="sign__hide-sensitive-info-button"
                >
                  {hidePassword.pass ? (
                    <BsEyeFill className="lgn__hide-sensitive-ib-icon" />
                  ) : (
                    <FaEyeSlash className="lgn__hide-sensitive-ib-icon" />
                  )}
                </span>
              </div>

              <div
                style={{
                  border: errors.password_confirmation
                    ? ".2rem solid #df4759"
                    : ".2rem solid #2B2B38",
                }}
                className="reg__form-group"
              >
                <input
                  onChange={(e) =>
                    setRegInfo((old) => ({
                      ...old,
                      password_confirmation: e.target.value,
                    }))
                  }
                  onFocus={() => {
                    setErrors((old) => ({
                      ...old,
                      password_confirmation: false,
                    }));
                  }}
                  value={regInfo.password_confirmation}
                  className="reg__form-control"
                  type={hidePassword.rePass ? "password" : "text"}
                  placeholder="Re-Enter Password"
                />
                <span
                  onClick={() =>
                    setHidePassword((old) => ({
                      ...old,
                      rePass: !hidePassword.rePass,
                    }))
                  }
                  className="sign__hide-sensitive-info-button"
                >
                  {hidePassword.rePass ? (
                    <BsEyeFill className="lgn__hide-sensitive-ib-icon" />
                  ) : (
                    <FaEyeSlash className="lgn__hide-sensitive-ib-icon" />
                  )}
                </span>
              </div>
            </div>

            <div className="reg__terms-container">
              <CheckButtons active={active} setActive={setActive} />
              You agree to the{" "}
              <span onClick={(e) => {
                       setShowRegModal({
                         ...showRegModal,
                         register: false,
                       });
                     }} style={{ color: "#FF9700" }}>
                <Link to="/terms/conditions" className="upload__more-text">
                  Terms of Use
                </Link>
              </span>{" "}
              &amp;{" "}
              <span onClick={(e) => {
                       setShowRegModal({
                         ...showRegModal,
                         register: false,
                       });
                     }} style={{ color: "#FF9700" }}>
                <Link to="/privacy/policy" className="upload__more-text">
                  Privacy policy
                </Link>
              </span>
            </div>

            <div className="reg__form-btn-group">
              <button
                disabled={clickProtect}
                style={{ opacity: clickProtect ? "0.6" : "1" }}
                onClick={handleSignUp}
                className="reg__login-btn"
              >
                <span className="reg__login-btn-text">
                  {" "}
                  {!isLoading ? <>SIGN UP</> : <LoootyLoader />}
                </span>
              </button>
            </div>

            <div className="reg__login-alternatives-container">
              {/* <a href={googleWebSignIn()} className='reg__btn-google'>
                <span>
                  {' '}
                  <FcGoogle
                    style={{ fontSize: '2rem'}}
                  />
                </span>

                <span className='reg__btn-google-text'>
                  Sign&nbsp;in&nbsp;with&nbsp;Google
                </span>
              </a> */}
              {/* <button className="reg__btn-facebook">
                <FaFacebookF
                  style={{ fontSize: "1.3rem", transform: "skewX(25deg)" }}
                />
                <span className="reg__btn-facebook-text">
                  Sign&nbsp;in&nbsp;with&nbsp;Facebook
                </span>
              </button> */}
              <div className="reg__form-recovery-container">
                <p>
                  {" "}
                  Already have an account?{" "}
                  <span
                     onClick={(e) => {
                       setShowRegModal({
                         ...showRegModal,
                         register: false,
                         signin: true,
                       });
                     }}
                    style={{ color: "#FF9700", cursor: "pointer" }}
                  >
                    Sign in!
                  </span>
                </p>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default SignUpModal;