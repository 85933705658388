const EarlyAccessSell = ({ toggleShowModal, fadeOut }) => {
  return (
    <div
      className={`reg__sign-up-overlay ${fadeOut ? 'lgn__fade-out-anim' : ''}`}
      style={{ color: '#ffffff' }}
    >
      <section className='reg__sign-up-modal user__withdrawal-modalea'>
        <div className='reg__close-btn-container'>
          <div onClick={toggleShowModal} className='reg__close-btn'>
            <span className='reg__close-btn-text'>X</span>
          </div>
        </div>

        <div className='reg__sign-up-forms-container withdrawal-modal'>
          <div>
            <p
              className=''
              style={{ fontWeight: '600', marginBottom: '0.2rem' }}
            >
              Early Access Creator:
            </p>
            <p>
              Join our discord server
              to be an Early Access Creator.
            </p>
            <p style={{ marginTop: '1.5rem' }}>
              <a href="https://discord.gg/ubJmSxEQhf" target="_blank" rel="noopener noreferrer" class='upload__more-text'>Discord.</a>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EarlyAccessSell;
