import CGCard, { CGCardSlim } from './CGCard';

const CatImg = '../../assets/image/loootydefault.jpg';


const CGBar = ({ categoryList, slim, style, onFilter }) => {
  return (
    <div style={style} className='landing__cg-card-container'>
      {categoryList?.length &&
        categoryList.map((category) => (
          <CGCard
            key={category?.id}
            text={category?.name}
            logoStyle={{ fontSize: '2.9rem' }}
            textStyle={{ fontSize: '.8rem' }}
            slim={slim}
            img={category?.image[0] ?? CatImg}
            click={() => onFilter(category?.name)}
          />
        ))}
    </div>
  );
};

export const CGBarSlim = ({ categoryList, style, onFilter }) => {
  return (
    <div style={style} className='landing__cg-card-container-slim'>
      {categoryList?.length &&
        categoryList.map((category) => (
          <CGCardSlim
            key={category.id}
            text={category.name}
            img={category?.image[0] ?? CatImg}
            logoStyle={{ fontSize: '3.6rem', paddingLeft: '.7rem' }}
            textStyle={{ fontSize: '.9rem' }}
            click={() => onFilter(category?.name)}
          />
        ))}
    </div>
  );
};

export default CGBar;
