import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
//import Cookies from 'js-cookie';
import EarlyAccessSell from '../../component/admin/modals/slogan/EarlyAccessSell';

export const OrangeBtn = ({ style, text = 'SELL', onClick }) => (
  <button style={style} className='landing__slogan-sell' onClick={onClick}>
    <span className='landing__slogan-sell-text'>{text}</span>
  </button>
);

const Slogan = () => {
  const navigate = useNavigate();
  const [showEarlyAccessSell, setShowEarlyAccessSell] = useState(false);
  return (
    <div className='landing__slogan'>
      <div className='landing__slogan-first-text'>
        Welcome to Africa, for all things CG and Game Dev
      </div>

      <div className='landing__slogan-second-text'>
        Premium Cultural Assets & more
      </div>

      <div className='landing__instruct-btn-container'>
        <button
          className='landing__slogan-browse'
          onClick={() => navigate('/search/result/all')}
        >
          <span className='landing__slogan-browse-text'>BROWSE</span>
        </button>

        <button
          className='landing__slogan-sell'
          //onClick={(e) => navigate('/open/store')}
          onClick={() => setShowEarlyAccessSell(!showEarlyAccessSell)}
        >
          <span className='landing__slogan-sell-text'>SELL</span>
        </button>
        {showEarlyAccessSell && (
          <EarlyAccessSell toggleShowModal={() => setShowEarlyAccessSell(false)} />
        )}
      </div>
    </div>
  );
};

export default Slogan;
