import { FaSearch } from 'react-icons/fa';
import { toast } from 'react-toastify'

const NavSearchBar = ({
  short,
  style,
  containerStyle,
  searchString,
  setSearchString,
  onSearch,
}) => {
  return (
    <section
      style={containerStyle}
      className='search__nav-search-skew-container'
    >
      <form
        style={{ ...style }}
        className='search__group-container'
        onSubmit={(e) => {
          e.preventDefault();
          if (searchString.length < 3) {
            toast.warning("Type at least 3 characters in the search box", {
              className: "toast__message",
              autoClose: 2000,
            });

            return;
          }
          onSearch();
        }}
      >
        <input
          style={{ padding: '.7rem 3rem' }}
          placeholder='Search for any product'
          className='search__input'
          value={searchString || ''}
          onChange={(e) => setSearchString(e.target.value)}
        />

        <button className='search__nav-search-btn' type='button' onClick={(e) => {
          e.preventDefault();
          if (searchString.length < 3) {
            toast.warning("Type at least 3 characters in the search box", {
              className: "toast__message",
              autoClose: 2000,
            });

            return;
          }
          onSearch();
        }}>
          <span style={{ transform: 'skewX(25deg) scaleX(-1)' }}>
            <FaSearch style={{ fontSize: '1.5rem' }} />
          </span>
        </button>
      </form>
    </section>
  );
};

export default NavSearchBar;